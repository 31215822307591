import firebase from 'firebase'
import React, { ReactElement, useEffect, useRef } from 'react'
import { useAuth } from '../authContext'
import {
  Provider as AuthUserContextProvider,
  useAuthUser,
  useAuthUserDispatch,
} from '../authUserContext'

const AuthUserContextContainer = (props: IProps): ReactElement => {
  const { children } = props

  const authUser = useAuthUser()
  const dispatch = useAuthUserDispatch()
  const { uid } = useAuth()

  const unsubRef = useRef<any>(null)
  const query = firebase
    .firestore()
    .collection('users')
    .doc(authUser.currentUid)

  useEffect(() => {
    if (!authUser.currentUid && uid) {
      dispatch('SET_CURRENT_UID', { currentUid: uid })
    }
  }, [authUser.currentUid, dispatch, uid])

  useEffect(() => {
    if (authUser.currentUid && !authUser.isListening) {
      unsubRef.current && unsubRef.current()
      const unsubscribe = query.onSnapshot((response) => {
        if (response.exists) {
          const doc = response.data()
          doc && dispatch('SET_USER', { user: doc })
        }
        unsubRef.current = unsubscribe
      })
      dispatch('SET_IS_LISTENING')
    }
    return (): void => {
      unsubRef.current && unsubRef.current()
    }
  }, [authUser.currentUid, authUser.isListening, dispatch, query])

  console.log(authUser)

  return <>{children}</>
}

const AuthUserContextContainerWithContext = (
  props: IProps,
): React.ReactElement => {
  const { children } = props
  return (
    <AuthUserContextProvider>
      <AuthUserContextContainer>{children}</AuthUserContextContainer>
    </AuthUserContextProvider>
  )
}

export default AuthUserContextContainerWithContext

interface IProps {
  children: React.ReactNode
}
