import { useReducer as _useReducer, useCallback } from 'react'

import { IState, TAction, IDispatch } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.user || undefined,
      }
    }
    case 'SET_CURRENT_UID': {
      return {
        ...state,
        currentUid: action.currentUid,
      }
    }

    case 'SET_IS_LISTENING': {
      return {
        ...state,
        isListening: true,
      }
    }
    case 'SET_IS_NOT_LISTENING': {
      return {
        ...state,
        isListening: false,
      }
    }
    default:
      return state
  }
}

export const useReducer = (initialState: IState): [IState, IDispatch] => {
  const [state, _dispatch] = _useReducer(reducer, initialState)

  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [_dispatch],
  )

  return [state, dispatch]
}
