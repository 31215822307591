import React from 'react'

import AuthContextContainer from './authContextContainer'
import AuthUserContextContainer from './authUserContextContainer'

const ContextProvider = (props: P): React.ReactElement => {
  const { children } = props

  return (
    <AuthContextContainer>
      <AuthUserContextContainer>{children}</AuthUserContextContainer>
    </AuthContextContainer>
  )
}

export default ContextProvider

type P = {
  children: React.ReactNode
}
