import firebase from 'firebase'
import { Routes } from '../../../routes'

const NETLIFY_URL =
  process.env.REACT_APP_ENVIRONMENT === 'localhost'
    ? process.env.REACT_APP_NETLIFY_FUNCTION_URL_LOCAL
    : process.env.REACT_APP_NETLIFY_FUNCTION_URL

export const getCookie = async () => {
  try {
    const response = await fetch(NETLIFY_URL + 'getAuthToken', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      body: JSON.stringify({ hello: 'hello' }),
      headers: {
        authorization: 'Bearer ' + process.env.REACT_APP_API2_TOKEN,
        'Content-Type': 'application/json',
      },
    })
    const { token } = await response.json()
    return token || null
  } catch (err) {
    console.log(err)
  }
}

export const deleteCookie = async () => {
  try {
    const response = await fetch(NETLIFY_URL + 'deleteCookie', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      body: JSON.stringify({ hello: 'hello' }),
      headers: {
        authorization: 'Bearer ' + process.env.REACT_APP_API2_TOKEN,
        'Content-Type': 'application/json',
      },
    })
    const jsonResponse = await response.json()
    console.log(jsonResponse)
  } catch (err) {
    console.log(err)
  }
}

export const signOut = async () => {
  await deleteCookie()
  await firebase.auth().signOut()
  window.location.href = Routes.AUTH
}
