import firebase from 'firebase'
import React, { ReactElement, useCallback, useEffect } from 'react'
import {
  Provider as AuthContextProvider,
  useAuth,
  useAuthDispatch,
} from '../authContext'
import { getCookie, signOut as _signOut } from '../authContext/helpers'

const AuthContextContainer = (props: IProps): ReactElement => {
  const { children } = props

  const { uid, token, signOut } = useAuth()

  const dispatch = useAuthDispatch()

  const signInWithToken = useCallback(async () => {
    if (!uid && token) {
      try {
        await firebase.auth().signInWithCustomToken(token)
        const user = firebase.auth().currentUser
        const currentUid = user?.uid
        currentUid && dispatch('SET_UID', { uid: currentUid })
      } catch (err) {
        console.log(err)
      }
    }
  }, [dispatch, token, uid])

  useEffect(() => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        return
      }
      const response = await getCookie()
      dispatch('SET_TOKEN', { token: response })
      dispatch('SET_COOKIE_FETCHED')
      await signInWithToken()
    })
  }, [dispatch, signInWithToken])

  useEffect(() => {
    if (signOut) {
      _signOut()
    }
  }, [signOut])

  if (uid) {
    console.log('user authenticated')
  }

  return <>{children}</>
}

const AuthContextContainerWithContext = (props: IProps): React.ReactElement => {
  const { children } = props
  return (
    <AuthContextProvider>
      <AuthContextContainer>{children}</AuthContextContainer>
    </AuthContextProvider>
  )
}

export default AuthContextContainerWithContext

interface IProps {
  children: React.ReactNode
}
