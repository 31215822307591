import { useReducer as _useReducer, useCallback } from 'react'

import { IState, TAction, IDispatch } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_UID': {
      return {
        ...state,
        uid: action.uid,
      }
    }
    case 'SET_TOKEN': {
      return {
        ...state,
        token: action.token,
      }
    }
    case 'SET_COOKIE_FETCHED': {
      return { ...state, cookieFetched: true }
    }
    case 'SIGN_OUT': {
      return { ...state, signOut: true }
    }
    default:
      return state
  }
}

export const useReducer = (initialState: IState): [IState, IDispatch] => {
  const [state, _dispatch] = _useReducer(reducer, initialState)

  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [_dispatch],
  )

  return [state, dispatch]
}
