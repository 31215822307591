import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from 'react'

//
import { IProvider, IState, IDispatch, TAction } from './types'
import { reducer } from './reducer'

const State = createContext<any>(null)
const Actions = createContext<any>(null)

export const useAuth = (): IState => useContext(State)
export const useAuthDispatch = (): IDispatch => useContext(Actions)

export const Provider = (props: IProvider): React.ReactElement => {
  const { children, initialState = {} } = props

  const [state, _dispatch] = useReducer(reducer, initialState)

  // Exposed dispatch function
  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [],
  )

  return (
    <State.Provider value={state}>
      <Actions.Provider value={dispatch}>{children}</Actions.Provider>
    </State.Provider>
  )
}
