import React from 'react'
import { Routes } from '../routes'
import { useAuth, useAuthDispatch } from '../state/context/authContext/context'

const Auth = () => {
  const { uid, token, cookieFetched } = useAuth()
  const dispatch = useAuthDispatch()

  if (cookieFetched && !token) {
    window.location.href = Routes.AUTH
  }
  return (
    <div className="App">
      {!uid && <div>LOADING</div>}

      {uid && (
        <div>
          <p>Yay, auth good! You can see this 🥳</p>
          <button onClick={() => (window.location.href = Routes.HOME)}>
            go to home
          </button>
          <br />
          <button onClick={() => dispatch('SIGN_OUT')}>
            sign me out please 👋🏼
          </button>
        </div>
      )}
    </div>
  )
}

export default Auth
