export const AUTH =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://auth.naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://auth.naeva-staging.app'
    : 'http://localhost:8888'

export const HOME =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://naeva-staging.app'
    : 'http://localhost:3001'

export const BOOKING =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://booking.naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://booking.naeva-staging.app'
    : 'http://localhost:3002'

export const SITE =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://site.naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://site.naeva-staging.app'
    : 'http://localhost:3003'

export const SYSADMIN =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://sysadmin.naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://sysadmin.naeva-staging.app'
    : 'http://localhost:3004'

export const IOT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://iot.naeva.app'
    : process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://iot.naeva-staging.app'
    : 'http://localhost:3005'

export const Routes = {
  AUTH,
  HOME,
  BOOKING,
  SITE,
  SYSADMIN,
  IOT,
}
